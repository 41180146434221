const {
    Carousel,
} = require('@fancyapps/ui/src/Carousel');

jQuery(function () {

    $(document).on('click', '.button-info', function () {
        $('.button-info').removeClass('font-weight-bold')
        $(this).addClass('font-weight-bold')
        $(".collapse.show").collapse('toggle')
    });


    // avvia carosello immagini instagram
    let info_carousel = document.getElementsByClassName("info_carousel");
    if (info_carousel) {
        for (const iterator of info_carousel) {
            
            new Carousel(iterator, {
                on: {
                    ready: () => {
                        iterator.classList.remove("d-none");
                    },
                },
                Dots: false,
                Navigation: true,
                infinite: true,
                center: true,
                slidesPerPage: 1,
                friction: 0.8,
                dragFree: false
            });
        }
        

    }

    
});
