import {
    get_url_params
} from '../modules/modules';

import {
    translations
} from '../modules/translations';

jQuery(function () {

    var debounce = null;

    // RICERCA CITTA' DURANTE L'INSERIMENTO NEL FORM
    $(document).on('keyup', '#checkout-form-city', function () {

        let query = $(this).val()

        if (query != '' && query.length >= 3) {

            clearTimeout(debounce);

            debounce = setTimeout(function () {

                $.ajax({
                    headers: {
                        "baro-xtoken": "ejcru4h9cy4793y7ycfbhwieaifjaijewj8!!@#[feresdakd"
                    },
                    "url": `https://registries.services.barocosmetics.com/api/v1/baro/anagrafica/citta/info/${query}/${window.lang.toUpperCase()}`,
                    "method": "GET",
                    "timeout": 15000,
                }).done(function (response) {

                    if (response.success === true) {

                        let li = ''

                        for (const result of response['data']) {
                            if (result.city != undefined) {
                                li += `<li class='list-group-item city'>${result.city} - ${result.state.state ?? ''} - ${result.state.code ?? ''} - ${result.zip_code ?? ''}</li>`
                            }
                        }

                        $('#checkout-form-city_list').html(li).fadeIn(200);

                    }
                });
            }, 200);

        } else
            $('#checkout-form-city_list').fadeOut('fast');

    });

    // INSERISCE LE PROVINCE NELLA SELECT ADEGUATA
    if (window.location.pathname === '/checkout') {
        $.ajax({
            headers: {
                "baro-xtoken": "ejcru4h9cy4793y7ycfbhwieaifjaijewj8!!@#[feresdakd"
            },
            "url": `https://registries.services.barocosmetics.com/api/v1/baro/anagrafica/stato/nazione/${window.lang.toUpperCase()}`,
            "method": "GET",
            "timeout": 15000,
        }).done(function (response) {
    
    
            if (response.success === true) {
    
                $('#checkout-state-select2').select2({
                    width: 'resolve',
                    placeholder: translations(window.lang).placeholder_state,
                    tags: true,
                })
    
                for (const result of response['data']) {
                    $('#checkout-state-select2').append(
                        new Option(result.state, result.code, false, false)
                    );
                }
    
                $('#checkout-state-select2').val($('#checkout-state-old').val()).trigger('change');
    
            } else {
                $('#checkout-state-old').attr('type', 'text').attr('name', 'state');
                $('#checkout-state-select2').remove();
            }
    
        }).fail(function () {
    
            $('#checkout-state-old').attr('type', 'text').attr('name', 'state');
            $('#checkout-state-select2').remove();
        });
    }


    // INSERISCE CITTA, PRONVICIA E CAP ALLA SELEZIONE DELLA CITTA'
    $(document).on('click', 'li.list-group-item.city', function () {
        var array = $(this).text().split(" - ");

        $('#checkout-form-city').val(array[0]);
        $('#checkout-form-cap').val(array[3]);

        if ($('#checkout-state-select2').find("option[value='" + array[2] + "']").length)
            $('#checkout-state-select2').val(array[2]).trigger('change');

        else
            $('#checkout-state-select2').append(new Option(array[1], array[2], true, true)).trigger('change');


        $('#checkout-form-city_list').fadeOut('fast');

    });




    // VALIDAZIONE FORM
    $('#checkout-form').on('submit', function (e) {
        let validation = true;

        // controlla pagamenti
        $(".checkout-payment input").each(function () {

            if (!$("form input[name='payment']").is(':checked')) {

                e.preventDefault();

                Swal.fire({
                    title: translations(window.lang).form_warning_title,
                    text: translations(window.lang).payment_warning_text,
                    icon: 'error',
                    confirmButtonText: 'Ok'
                })

                validation = false;

                return false
            }
        })

        // controlla form
        $(".checkout-form input").each(function () {

            if ($(this).val() == '' && $(this).data('check')) {

                e.preventDefault();

                Swal.fire({
                    title: translations(window.lang).form_warning_title,
                    text: translations(window.lang).form_warning_text,
                    icon: 'error',
                    confirmButtonText: 'Ok'
                })

                validation = false;

                return false
            }
        })

        if (validation) {
            $('#loadingModal').modal({
                keyboard: false,
                focus: true,
                show: true,
                backdrop: 'static',
            })
        }

    });

    // FUNZIONE DI INSERIMENTO DATI A DB TRAMITE AJAX AL BLUR DEL FORM
    $(document).on('focusout', '.checkout-form input[name=email]', function () {

        $.ajax({
            url: '/update_email',
            method: 'POST',
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
                'X-CSRF-TOKEN': $('meta[name=_token]').attr('content')
            },
            data: {
                ids: get_url_params('ids'),
                id: $("input[name='id']").val(),
                email: $('#checkout-form-email').val()
            }
        });
    });

});
