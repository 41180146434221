module.exports = {
    
    is_mobile: () => {
        return (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) ? true : false;
    },

    get_url_params: (search) => {
        const urlParams = new URLSearchParams(window.location.search);
        return urlParams.get(search);
    },

    get_cookie: (cname) => {
        var name = cname + "=";
        var decodedCookie = decodeURIComponent(document.cookie);
        var ca = decodedCookie.split(';');
        for (var i = 0; i < ca.length; i++) {
            var c = ca[i];
            while (c.charAt(0) == ' ') {
                c = c.substring(1);
            }
            if (c.indexOf(name) == 0) {
                return c.substring(name.length, c.length);
            }
        }
        return false;
    },

    is_double_clicked: (element, time) => {
        if (element.data("isclicked")) return true;

        element.data("isclicked", true);

        setTimeout(function () {
            element.removeData("isclicked");
        }, time);

        return false;
    },

    scroll_to_anchor: (target, position) => {
        const destination = $("#" + target);
        $('html,body').animate({
            scrollTop: destination.offset().top + position
        }, 'slow');
    }
};
