import {
    is_double_clicked,
    scroll_to_anchor
} from "../modules/modules";


jQuery(function () {
    "use strict";

     // rallenta scroll
    $(document).on('click', 'a[data-target]', function () {
        scroll_to_anchor($(this).data('target'), -100);
    });

    //  APRE MODALE LOADING E AGGIUNGE GIF LOADING AI LINK
    $(document).on('click', '.loading', function (e) {
        if (is_double_clicked($(this), 3500)) {
            e.preventDefault()
            return false;
        }
        $(this).attr('disabled', 'disabled');
        $(this).children('div').text('');
        $(this).children('.spinner-border').removeClass('d-none')
    });

    // APRE MODALE LOADING E AGGIUNGE GIF LOADING AI FORM
    $(document).on('submit', 'form', function (e) {
        if (is_double_clicked($(this), 3500)) {
            e.preventDefault()
            return false;
        }
        $(this).children('button').attr('disabled', 'disabled');
        $(this).children('button').children('div').text('');
        $(this).children('button').children('.spinner-border').removeClass('d-none')
        $(this).parent('form').trigger('submit')
    });


    // APRE MODALE LOADING
    $('.openModal').on('click', function () {
        $('#loadingModal').modal({
            keyboard: false,
            show: true
        });
    });


    // chiude la modale search quando si clicca sul dom
    $('body').on('click', function (event) {
        if (!$(event.target).closest('#modal_search').length && !$(event.target).is('.modal_search') && !$(event.target).is('#navbar-search')) {
            $(".modal_search").fadeOut();
        }
    });

    // nella search bar riporta la ricerca in minuscolo
    $(document).on('submit', '.navbar-search-form', function (e) {
        $(this).children('input[name=search]').val($(this).children('input[name=search]').val().toLowerCase())
    });

    // gestisce rotazione messaggi nella barra sopra la navbar
    setInterval(() => {

        let active = $('#campaign_bar_top p.active');
        active.addClass('slide')

        setTimeout(() => {

            if (active.next().length > 0) {
                active.addClass('d-none').removeClass('slide').removeClass('active')
                active.next().addClass('active').removeClass('d-none')
            } else {
                active.addClass('d-none').removeClass('slide').removeClass('active')
                $('#campaign_bar_top p').first().addClass('active').removeClass('d-none')
            }

        }, 250);
    }, 4000)



    $('.sidebar-dismiss, .sidebar-overlay').on('click', function () {
        $('.sidebar').removeClass('active');
        $('.sidebar-overlay').fadeOut();
    });

    $('.sidebar-collapse').on('click', function () {
        $('#' + $(this).data('item')).addClass('active');
        $('.sidebar-overlay').fadeIn();
        $('.collapse.in').toggleClass('in');
    });

});
