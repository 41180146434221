const {
    Carousel,
} = require('@fancyapps/ui/src/Carousel');

const {
    Autoplay,
} = require('@fancyapps/ui/dist/carousel.autoplay.esm');

const {
    Fancybox
} = require('@fancyapps/ui/src/Fancybox');

jQuery(function () {
    "use strict";

    // AVVIA FANCYBOX
    Fancybox.bind("[data-fancybox]", {
        infinite: true,
        Toolbar: {
            display: [
                "thumbs",
                "close",
            ],
        },
    });

    // AVVIA CAROSELLO IMMAGINI DEL PRODOTTO
    if (document.getElementById("single-carousel-main")) {

        Carousel.Plugins.Autoplay = Autoplay;

        const mainCarousel = new Carousel(document.getElementById("single-carousel-main"), {
            Dots: false,
            slidesPerPage: 1,
            center: true,
        });

        new Carousel(document.getElementById("single-carousel-nav"), {
                Sync: {
                    target: mainCarousel,
                },
                Autoplay: {
                    timeout: 2000
                },
                Dots: false,
                Navigation: false,
                infinite: false,
                center: true,
                slidesPerPage: 1,
            }), $('.single-carousel .spinner-border').hide(),
            $('#single-carousel-nav').fadeIn('fast'),
            $('#single-carousel-main').fadeIn('fast');
    }

    // AVVIA CAROSELLO IMMAGINI PRODOTTI RELATIVI ALL'OFFERTA
    let single_carousel_related_products = document.getElementById("single-carousel-related_products");

    if (document.getElementById("single-carousel-related_products")) {

        Carousel.Plugins.Autoplay = Autoplay;

        new Carousel(single_carousel_related_products, {
            on: {
                ready: () => {
                    single_carousel_related_products.classList.remove("d-none");
                },
            },
            Autoplay: {
                timeout: 1500,
            },
            Dots: true,
            Navigation: true,
            infinite: false,
            center: true,
            slidesPerPage: 1,
            friction: 0.8,
            dragFree: true
        });

    }


});
