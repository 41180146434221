const {
    Carousel
} = require('@fancyapps/ui/src/Carousel');

import {
    is_double_clicked,
    get_url_params
} from "../modules/modules";

jQuery(function () {
    "use strict";

    // TRIGGERA SUBMIT PREMENDO L'IMMAGINE NEL CAROSELLO
    $(document).on('click', '.carousel__slide img', function () {
        $(this).parent().find('form').trigger('submit');
    });

    // CARICA MODALE CROSS SELL O MANDA A CHECKOUT
    $(document).on('click', '.sendOrder', function (e) {

        if (is_double_clicked($(this), 3500)) {
            e.preventDefault()
            return false;
        }

        $(this).attr('disabled', 'disabled');
        $(this).html('<div class="spinner-border text-light" role="status"></div>');

        const ids = get_url_params('ids');
        const route = $(this).hasClass('sendOrderFunnelOne') ? '/manage_order_product' : '/get_cross';

        $.ajax({
            url: route,
            method: 'GET',
            data: {
                ids: ids,
                product: $(this).data('product'),
                method: 'getCross'
            },
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
                'X-CSRF-TOKEN': $('meta[name=_token]').attr('content')
            },
            error: function () {
                location.href = `/checkout?ids=${ids}`;
            }
        }).then(response => {

            if (response.result !== undefined && response.result == false)
                location.href = response.data;
            else
                show_modal_cross_sell(response)
        });

    });

    function show_modal_cross_sell(response) {
        $('#modal_cross_container').html(response);
        $('#modal_cross').modal({
            show: true,
            focus: true,
            backdrop: 'static',
            keyboard: false,
        });

        new Carousel(document.querySelector("#modal_cross_multiple_1_carousel"), {
            inifinite: true,
            slidesPerPage: 1,
            center: false,
            dragFree: true,
            Navigation: {
                prevTpl: '<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M11 5l-7 7 7 7"/><path d="M4 12h16"/></svg>',
                nextTpl: '<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M4 12h16"/><path d="M13 5l7 7-7 7"/></svg>',
            },
        });
    }

});
