window.Popper = require('popper.js').default;

window.$ = window.jQuery = require('jquery');

import swal from 'sweetalert2';
window.Swal = swal;

require('bootstrap/dist/js/bootstrap');

require('select2/dist/js/select2');

require('./general/app');

// landing 
require('./landing/app');

// checkout
require('./checkout/app')

// products
require('./products/app')

// info
require('./info/main');

// thankyou page
require('./thankyou/main');

