module.exports = {

    translations: (lang) => {
        switch (lang) {
            case 'it':
                return module.exports.italian();
            case 'cz':
                return module.exports.czech();
            case 'en':
                return module.exports.english();
            case 'es':
                return module.exports.spanish();
            case 'de':
                return module.exports.deutsch();
        }
    },


    italian: () => {
        return {
            form_warning_title: 'Attenzione!',
            form_warning_text: 'Tutti i campi con * devono essere compilati',
            payment_warning_text: 'Seleziona il metodo di pagamento',
            currency_symbol: '€',
            currency_locale: 'it-IT',
            currency_float: 2,
            currency_code: 'EUR',
            placeholder_state: 'Seleziona la Provincia'
        }
    },

    deutsch: () => {
        return {
            form_warning_title: 'Aufmerksamkeit!',
            form_warning_text: 'Alle mit * gekennzeichneten Felder müssen ausgefüllt werden',
            payment_warning_text: 'Wählen Sie eine Bezahlart',
            currency_symbol: '€',
            currency_locale: 'de-DE',
            currency_float: 2,
            currency_code: 'EUR',
            placeholder_state: 'Wählen Sie die Provinz aus'
        }
    },

    czech: () => {
        return {
            form_warning_title: 'Pozor!',
            form_warning_text: 'Všechna pole s * musí být vyplněna',
            payment_warning_text: 'Vyberte způsob platby',
            currency_symbol: 'Kč',
            currency_locale: 'cs-CZ',
            currency_float: 0,
            currency_code: 'CZK',
            placeholder_state: 'Vyberte provincii'
        }
    },

    english: () => {
        return {

            form_warning_title: 'Beware!',
            form_warning_text: 'All fields with * must be filled in',
            payment_warning_text: 'Select your payment method',
            currency_symbol: '€',
            currency_locale: 'it-IT',
            currency_float: 2,
            currency_code: 'EUR',
            placeholder_state: 'Select the Province'
        }
    },

    spanish: () => {
        return {
            form_warning_title: '¡Advertencia!',
            form_warning_text: 'Todos los campos marcados con * son obligatorios',
            payment_warning_text: 'Selecciona tu forma de pago',
            currency_symbol: '€',
            currency_locale: 'es-ES',
            currency_float: 2,
            currency_code: 'EUR',
            placeholder_state: 'Selecciona la Provincia'
        }
    }

};
