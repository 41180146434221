const {
    Carousel,
} = require('@fancyapps/ui/src/Carousel');

const {
    Autoplay,
} = require('@fancyapps/ui/dist/carousel.autoplay.esm');

const {
    get_url_params,
} = require('../modules/modules')

window.addEventListener('loadInstagramCarousel', () => {
    // avvia carosello immagini instagram
    let instagram_carousel = document.getElementById("instagram_carousel");

    if (instagram_carousel) {

        Carousel.Plugins.Autoplay = Autoplay;

        new Carousel(instagram_carousel, {
            Autoplay: {
                timeout: 2500,
            },
            Dots: true,
            Fill: true,
            Navigation: false,
            infinite: true,
            center: true,
            slidesPerPage: 1,
            friction: 0.8,
            dragFree: true
        });

    }
})

window.addEventListener('loadProductsCarousel', () => {

    // avvia carosello prodotti 
    let products_carousel = document.getElementById("products_carousel");

    if (products_carousel) {

        Carousel.Plugins.Autoplay = Autoplay;

        new Carousel(products_carousel, {
            Autoplay: {
                timeout: 3500,
            },
            Navigation: true,
            Dots: false,
            infinite: true,
            center: false,
            slidesPerPage: 1,
            friction: 0.8,
            dragFree: false,
            adaptiveHeight: true
        });

    }
})


jQuery(function () {
    "use strict";

    // chiude sidebar left al click
    $('#menu.sidebar-left li').on('click', function () {
        $('.sidebar').removeClass('active');
        $('.sidebar-overlay').fadeOut();
    });


    // MOSTRA MODALE NEWSLETTER
    if (document.cookie.indexOf('newsletter') < 0 || get_url_params('newsletter')) {
        setTimeout(() => {
            document.cookie = "newsletter=1; max-age=10080; path=/; SameSite=Strict;"; // 1 day
            $('#newsletter').modal('show');
        }, 2000);
    }

});
