jQuery(function () {
    setTimeout(() => {
        if ($('#thankyou-header').hasClass('d-none')) {
            $(".chspt-order-opt-in-widget--opt-in-cta").trigger('touchstart'); 
            $(".chspt-order-opt-in-widget--opt-in-cta").trigger('click');  
            // $('.chspt-order-opt-in-widget select').val(window.lang).trigger('change');
        }
    }, 1500);
    
});
